import React from 'react'

import { Link } from 'gatsby'

import Button from './../button'
import Icon from './../icon'


const Toolbar = () => (
  <div className="toolbar">
    <Button theme="primary" to="/client-add/">
      <Icon icon="client" theme="primary" modifier="plus" />
      <span className="button__label">Add Client</span>
    </Button>
  </div>
);

export default Toolbar
