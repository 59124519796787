import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const Select = props => (
  <div className={props.className}>
    {props.label &&
      <div className={`${props.className}__label`}>
        {props.label}
      </div>
    }
    <div className={`${props.className}__select`}>
      <select
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        tabIndex={props.tabIndex}
      >
        {props.nonOption &&
          <option key={null} disabled>{props.nonOption}</option>
        }
        {props.options.map(option => (
          <option key={option.id} value={option.id}>{option.label}</option>
        ))}
      </select>
    </div>
  </div>
);

Select.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  nonOption: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  })),
  value: PropTypes.string
};

Select.defaultProps = {
  className: 'field-select',
  nonOption: null,
  options: []
};

export default Select;
