import React from 'react'

import Header from '../header'
import Menu from '../header/menu'
import Toolbar from '../header/toolbar'
import Account from '../header/account'
import { UserAPIProvider } from '../../services/user'
import { LocationsAPIProvider } from '../../services/locations'
import { LocationProvider } from '../../context/location'
import {ErrorsProvider} from '../../context/errors';

const DashboardLayout = ({ title, children }) => {
  return (
    <ErrorsProvider>
      <LocationsAPIProvider>
        <LocationProvider>
          <UserAPIProvider>
            <>
              <Header siteTitle={title}>
                <Menu />
                <Toolbar />
                <Account />
              </Header>
              <div className="page">
                {children}
              </div>
            </>
          </UserAPIProvider>
        </LocationProvider>
      </LocationsAPIProvider>
    </ErrorsProvider>
  )
}

export default DashboardLayout
