import React from 'react';
import { Link } from 'gatsby'

import CustomLink from '../Link';

import Icon from '../icon'

const activeClass = 'active'

const NavItem = ({ linkTo, children }) => (
  <li>
    <Link
      to={linkTo}
      activeClassName={activeClass}
    >
      {children}
    </Link>
  </li>
)

const NavItemDeep = ({ linkProps, children }) => (
  <li>
    <CustomLink { ...linkProps }>{children}</CustomLink>
  </li>
)

const Menu = () => (
  <nav className="header-nav" role="navigation">
    <ul className="header-nav--links">
      <NavItem linkTo="/">
        <Icon icon="calendar" />
        Calendar
      </NavItem>
      <NavItem linkTo="/clients/">
        <Icon icon="client" />
        Clients
      </NavItem>
      {/* JOC-442: Hide Accounting and Research as these will likely be built in Drupal
      <NavItem linkTo="/accounting/">
        <Icon icon="accounting" />
        Accounting
      </NavItem>
      <NavItemDeep linkProps={{
        to: "/research/",
        getProps: ({ isPartiallyCurrent }) => (
          isPartiallyCurrent ? { className: activeClass } : null
        )
      }}>
        <Icon icon="research" />
        Research
      </NavItemDeep>
      */}
    </ul>
  </nav>
)

export default Menu
