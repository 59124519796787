import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link } from "@reach/router";

const ErrorsOnPageMessage = ({closeDialog, moveOnTo, errors}) => {

  const moveOnHandler = () => {
    closeDialog();
    errors.setErrorStatus(false);
  };

  return (
      <>
        <div>There are still errors present. Are you sure you want to leave this screen?</div>
        <div className={'button-group'}>
          <div className={'button button--primary'} onClick={closeDialog}>No, stay here</div>
          <Link className={'button button--secondary'} to={moveOnTo} onClick={moveOnHandler}>Yes, leave screen</Link>
        </div>
      </>
  );
};



export default ErrorsOnPageMessage;