import React from 'react'
import { Link } from 'gatsby';

import Icon from '../icon'

const ClientName = ({ name, folder, id, modifiers = [] }) => (
  <div className={'client ' + modifiers.map(modifier => `client--${modifier}`).join(' ')}>
    <div className="client--name">
      <Link
        to={`/client/${id}`}>
        {name}
      </Link>
    </div>
    {folder &&
      <div className="client--folder">
        <Icon icon="folder"/> {folder ? `${folder}` : 'Unassigned'}
      </div>
    }
  </div>
);

export default ClientName
