import React from 'react'
import PropTypes from 'prop-types';
import ReportPreviewLayout from '../layoutReport';

const status = capacity => {
  if (capacity === 0) {
    return 'is-full'
  }

  return capacity < 0 ? 'is-over' : 'is-under'
}
const Availability = ({ capacity, availability }) => (
  <div className="calendar-availability">
    <span className="calendar-availability--title">Available</span>
    <span className="calendar-availability--amount">
      <span className={
        `calendar-availability--capacity calendar-availability--capacity__${status(capacity)}`
      }>{capacity}</span> / {availability}
    </span>
  </div>
)

Availability.propTypes = {
  capacity: PropTypes.number,
  availability: PropTypes.number
}

export default Availability
