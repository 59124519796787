import React from 'react'

import CalendarAppt from './appointment'
import CalendarDay from './day'
import CalendarToday from './today'
import Log from './log'
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import moment from "moment-timezone";

const getMaxAppointmentCount = (appointments) => {
  if (appointments.length === 0) {
    return 0;
  }
  const groupByDay = groupBy(appointments, 'day');
  const sortByLength = sortBy(groupByDay, 'length').reverse();
  return sortByLength[0].length;
}

const CalendarSingleDay = ({ client = null, appointments, days, defaultAvailability, refresh }) => (
  <div className="calendar calendar--singleday">
    <div className="calendar--label-spacer"></div>
    <div className="calendar-day-header">
      <CalendarDay key={0} day={days[0]} />
    </div>
    {Object.keys(appointments).map((key, i) => {
      const appointmentsToday = appointments[key].filter(appointment => appointment.day === days[0].day);
      // Create a dummy array to populate zebra stripes;
      const stripes = Array.apply(null, Array(getMaxAppointmentCount(appointmentsToday) + 1));

      return (
        <React.Fragment key={i}>
          <div className="calendar--label">{key}</div>
          <div className="calendar-day">
            <Log
              key={key}
              day={days[0]}
              slot={key}
              count={appointmentsToday.length}
              defaultAvailability={defaultAvailability}
              refresh={refresh}
            />
            {stripes.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`calendar__stripe calendar__stripe--${
                    i % 2 ? 'even' : 'odd'
                  }`}
                  style={{ gridRow: `${i + 2} / ${i + 3}` }}
                ></div>
              )
            })}
            {days.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`calendar__column`}
                  style={{ gridColumn: `${i + 1} / ${i + 2}` }}
                ></div>
              )
            })}
            {appointmentsToday
              .sort((a, b) => {
                if (a.date.start > b.date.start) {
                  return 1
                }
                if (a.appointment === '3') {
                  return -1
                }
                if (b.appointment === '3') {
                  return 1
                }
                return parseInt(a.appointment, 10) - parseInt(b.appointment, 10)
              })
              .map((appointment, i) => (
                <CalendarAppt
                  key={appointment.id}
                  id={appointment.id}
                  data={appointment}
                  showTime={true}
                  refresh={refresh}
                />
              ))}
          </div>
        </React.Fragment>
      )
          })}
    <CalendarToday days={days} />
  </div>
);

export default CalendarSingleDay
