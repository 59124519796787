/**
 *  API: client
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}`;

export const clientLoad = id => () => request(route(id));

// Create a Context
const ClientAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

const getAppointments = response => {
  if (response && response.data) {
    return response.data.appointments;
  }

  return [];
}

const getAppointment = (response, number) => {
  const appointments = getAppointments(response);
  return appointments.find((appointment => appointment.appointment === number));
}

const getLastAppointment = (response, number = 3) => {
  const appointments = getAppointments(response)
    .filter(appt => parseInt(appt.appointment, 10) <= number)
    .sort((apptA, apptB) => apptB.appointment - apptA.appointment);

  return appointments.length > 0
    ? appointments[0]
    : null;
}

const getContactEmails = response => {
  if (response && response.data) {
    return [
      response.data.email,
      ...response.data.contacts.map(contact => contact.email)
    ];
  }

  return [];
}

const getEmail = response => {
  if (response && response.data) {
    return response.data.email;
  }

  return '';
}

export const ClientAPIProvider = ({ children, id }) => {
  const loader = useLoads('client', clientLoad, {
    variables: [id],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ClientAPIContext.Provider value={ Object.assign({}, loader, {
      id,
      data,
      getAppointments: () => getAppointments(loader.response),
      getAppointment: number => getAppointment(loader.response, number),
      getLastAppointment: number => getLastAppointment(loader.response, number),
      getContactEmails: () => getContactEmails(loader.response),
      getEmail: () => getEmail(loader.response)
    })}>
      {children}
    </ClientAPIContext.Provider>
  )
}

ClientAPIProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element
}

export default ClientAPIContext
