import moment from 'moment-timezone';
import startOfWeek from 'date-fns/start_of_week'
import endOfWeek from 'date-fns/end_of_week'
import startOfDay from 'date-fns/start_of_day'
import endOfDay from 'date-fns/end_of_day'
import differenceInDays from 'date-fns/difference_in_days'
import format from 'date-fns/format';


import isSaturday from 'date-fns/is_saturday'
import isSunday from 'date-fns/is_sunday'
import isMonday from 'date-fns/is_monday'


const qs = require('qs')

const validWeek = (start, end) => {
  return isMonday(start) && isSunday(end) && (differenceInDays(end, start) === 6)
}

const defaultWeek = (tz) => {
  const date = new Date();

  return {
    start: moment(date).tz(tz).startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment(date).tz(tz).endOf('isoWeek').format('YYYY-MM-DD')
  };
}

export const getWeekRangeFromDate = (date, tz) => {
  return {
    start: moment(date).tz(tz).startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment(date).tz(tz).endOf('isoWeek').format('YYYY-MM-DD')
  };
}

export const getWeekRangeFromNow = (tz) => {
  return {
    start: moment().tz(tz).startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment().tz(tz).endOf('isoWeek').format('YYYY-MM-DD')
  };
}

export const getWeekRangeFromUrl = (url) => {
  let dates = null;

  if (url.search !== '') {

    const search = qs.parse(url.search, { ignoreQueryPrefix: true })

    if (search.hasOwnProperty('start') && search.hasOwnProperty('end')) {
      const { start, end } = search

      if (validWeek(start, end)) {
        dates = search
      }
    }
  }

  return dates;
}

const getWeekRange = (url, tz) => {

  let dates = defaultWeek(tz);

  if (url.search !== '') {

    const search = qs.parse(url.search, { ignoreQueryPrefix: true })

    if (search.hasOwnProperty('start') && search.hasOwnProperty('end')) {
      const { start, end } = search

      if (validWeek(start, end)) {
        dates = search
      }
    }
  }
  return dates;
}

export default getWeekRange
