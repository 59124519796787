import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import Select from './select';
import TasAPIContext from '../../services/tas';


const TaSelect = props => {
  const optionsValidate = (id, options) => {
    if (!id) {
      return options;
    }
    if (!options.map((option) => (option.id)).includes(id)) {
      options.push({"id": id, "label": "User " + id});
    }
    return options;
  }

  const tasAPI = useContext(TasAPIContext);
  const { isResolved, isIdle, options } = tasAPI;

  // Refetch the client on each page load.
  useEffect(() => {
    // Load if we
    if (isIdle) {
      tasAPI.load();
    }
  }, [window.location.pathname])

  if (!isResolved) {
    return (<span>Loading</span>);
  }

  return (
    <Select
      {...props}
      nonOption="Select a TA"
      options={optionsValidate(props.value, options)}
      className="select--minimal"
    >
    </Select>
  );
};

TaSelect.propTypes = {}

TaSelect.defaultProps = {
  options: []
}

export default TaSelect
