import React from 'react';

const CalendarDay = ({ day }) => (
  <div className={`calendar__cell calendar__cell--${day.today ? 'today' : day.past ? 'past' : 'future'} calendar-week-heading`}>
    <span className="calendar-heading--num">{day.date.format('DD')}</span>
    <div>
      <span className="calendar-heading--mon">{day.date.format('MMM')}</span>
      <span className="calendar-heading--day">{day.date.format('dddd')}</span>
    </div>
  </div>
);

export default CalendarDay
