import React from 'react'

const FieldTextArea = ({ id, label, value, placeholder, onChange }) => (
  <div className="field-text">
    <label className="field-text--label" htmlFor={id}>{label}</label>
    <div className="field-text--input">
      <textarea id={id} name={id} placeholder={placeholder} value={value} onChange={onChange} rows="6" />
    </div>
  </div>
);

export default FieldTextArea
