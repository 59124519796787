import React from 'react';
import { format, addDays } from 'date-fns';
import Icon from './icon';

const label = date => format(date, 'MMM DD, YYYY');

const MenuDay = ({ start, prev, next, open, toggle, weekday }) => (
  <div className="container">
    <div className={`calendar-menu--week menu-tab__${open
      ? 'is-open'
      : 'is-closed'}`}>
      <button className="button-prev button--circle" onClick={prev}
        disabled={open}>
        <Icon icon="arrow" theme="primary"/>
        <span className="visually-hidden">Prev</span>
      </button>
      <div className="calender-menu--current" onClick={toggle}>
        {label(addDays(start, weekday))} <Icon
          icon="arrowCircle" theme="primary"/>
      </div>
      <button className="button-next button--circle" onClick={next}
        disabled={open}>
        <Icon icon="arrow" theme="primary"/>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
);

export default MenuDay;
