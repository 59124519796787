/**
 *  Context: Errors
 */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../components/auth/authContext';

// Create a Context
const ErrorsContext = React.createContext();

export const ErrorsProvider = ({ children, duration = 200 }) => {
  const [
    errorStatus,
    setErrorStatus
  ] = useState(false);

  const [
    errorMessage,
    setErrorMessage
  ] = useState(null);

  const value = {
    errorStatus,
    errorMessage,
    setErrorStatus,
    setErrorMessage,
    clearErrorMessage: () => {
      setErrorStatus(false);
      setErrorMessage(null);
    }
  };


  return (
    <ErrorsContext.Provider value={value}>
      {children}
    </ErrorsContext.Provider>
  )
}

export const ErrorsConsumer = ErrorsContext.Consumer

ErrorsProvider.propTypes = {
  children: PropTypes.element
}

export default ErrorsContext
