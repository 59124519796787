import React, { Component, useState, useContext, useEffect } from 'react'

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment-timezone';

import eachDay from 'date-fns/each_day'
import Icon from '../icon'
import LocationContext from './../../context/location';

import CalendarCaption from './../calendar/caption'
import { convertDateToDateTime } from '../../utils/dateTime';

function Navbar({ onPreviousClick, onNextClick, month}) {
  return (
    <div className="DayPicker-NavBar">
      <button key="previous" tabIndex="0" onClick={() => onPreviousClick()} aria-label="Previous Year" className="DayPicker-NavButton button--prev navButtonPrev">
        <Icon icon="arrow" theme="primary" />
        <span className="visually-hidden">Prev</span>
      </button>
      <div className="DayPicker-year calender-menu--current">{month.getFullYear()}</div>
      <button key="next" tabIndex="0" aria-label="Next Year" onClick={() => onNextClick()} className="DayPicker-NavButton button--next navButtonNext">
        <Icon icon="arrow" theme="primary" />
      </button>
  </div>
  );
}

const DateYear = ({start, end, open, setRange, setWeekday}) => {
  const location = useContext(LocationContext);
  const tz = location.timezone();

  const handleDayChange = date => {
    const weekdayIndex = moment(date).day();
    const locDate = convertDateToDateTime(date, tz);
    setRange(
      locDate.clone().tz(tz).startOf('isoWeek').format('YYYY-MM-DD'),
      locDate.clone().tz(tz).endOf('isoWeek').startOf('day').format('YYYY-MM-DD'),
    );
    setWeekday(weekdayIndex === 0 ? weekdayIndex : weekdayIndex - 1);
  }

  const selectedDays = eachDay(start, end)
  const daysAreSelected = selectedDays.length > 0
  const currentYear = moment(start).tz(tz).startOf('year').toDate();


  const modifiers = {
    start: start,
    end: end,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
  }

  return (
    <div className={`overlay-pane--inner pane__${open ? 'is-open' : 'is-closed'}`}>
      <div className="card card--panel card--overlay">
        <div className="date-picker date-picker--year">
        <div className="calender-menu--current"></div>
          <DayPicker
            month={currentYear}
            selectedDays={selectedDays}
            numberOfMonths={12}
            firstDayOfWeek={1}
            modifiers={modifiers}
            onDayClick={handleDayChange}
            navbarElement={<Navbar />}
            captionElement={({date}) => <CalendarCaption date={date} />}
            pagedNavigation
          />
        </div>
      </div>
    </div>
  )
}

export default DateYear
