import React from 'react'
import { navigate } from 'gatsby';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';


import AddApptButton from '../appointment/addApptButton'
import CalendarDay from './day'
import CalendarToday from './today'
import Log from './log'

import ClientAPIContext, { ClientAPIProvider } from '../../services/client';
import CalendarAppt from './appointment';

const added = (client, appointments) => (
  appointments.filter(appointment => appointment.id === client.id).length > 0
)

const getMaxAppointmentCount = (appointments) => {
  if (appointments.length === 0) {
    return 0;
  }
  const groupByDay = groupBy(appointments, 'day');
  const sortByLength = sortBy(groupByDay, 'length').reverse();
  return sortByLength[0].length;
}

const CalendarWeek = ({ client = null, appointments, days, defaultAvailability, add, number, refresh }) => {
  const clientAPI = React.useContext(ClientAPIContext);
  const appointment = clientAPI.getAppointment(number);
  const id = appointment
    ? appointment.id
    : null;

  return (
    <div className="calendar">
      <div className="calendar--label-spacer"></div>
      <div className="calendar-week-header">
        {days.map(day => <CalendarDay key={day.key} day={day} />)}
      </div>
      {Object.keys(appointments).map((key, i) => {
        // Create a dummy array to populate zebra stripes;
        const stripes = Array.apply(null, Array(getMaxAppointmentCount(appointments[key]) + 1));

        return (
        <React.Fragment key={i}>
          <div className="calendar--label">{key}</div>
          <div className="calendar-week">
            {days.map(day => (
              <Log
                key={`${day.key}-${key}`}
                day={day}
                slot={key}
                count={appointments[key].filter(appointment => appointment.day === day.day).length}
                defaultAvailability={defaultAvailability}
                refresh={refresh}
                />
            ))}
            {stripes.map((item, i) => {
              return (<div key={i} className={`calendar__stripe calendar__stripe--${i % 2 ? 'even' : 'odd'}`} style={{gridRow: `${i + 2} / ${i + 3}`}}></div>);
            })}
            {days.map((item, i) => {
              return (<div key={i} className={`calendar__column`} style={{gridColumn: `${i + 1} / ${i + 2}`}}></div>);
            })}
            {(add && days.map(day => (
              <AddApptButton
                key={`appt-${day.key}-${key}`}
                day={day.day}
                date={day.date}
                slot={key}
                add={add}
                number={number}
                client={client}
                refresh={() => {
                  // Redirect to client page if this was an existing appointment.
                  if (id) {
                    navigate(`/client/${client}`);
                  }
                  // Redirect to client page if this was the 4th appointment.
                  else if (parseInt(number, 10) === 4) {
                    navigate(`/client/${client}`);
                  }
                  // Else, redirect to the next step
                  else if (parseInt(number, 10) < 3) {
                    navigate(`/client/${client}/edit/appt/${(parseInt(number, 10) + 1)}`);
                  } else {
                    navigate(`/client/${client}/edit/payment`);
                  }

                  refresh();
                  if (add && client) {
                    clientAPI.load(client);
                  }
                }}
                id={id}
              />
            )))}
            {appointments[key]
              .sort((a, b) => {
                if (a.appointment === '3') {
                  return -1;
                }
                if (b.appointment === '3') {
                  return 1;
                }
                return parseInt(a.appointment, 10) - parseInt(b.appointment, 10);
              })
              .map((appointment) => (
              <CalendarAppt key={appointment.id} id={appointment.id} data={appointment} />
            ))}
          </div>
        </React.Fragment>
      )})}
      <CalendarToday days={days} />
    </div>
  );
}

export default CalendarWeek
