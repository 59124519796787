import React from 'react'
import { Link } from 'gatsby'

const Header = ({ siteTitle = 'JOCRF', children }) => (
  <header className="site-header">
    <h1 className="header-title">
      <Link to="/" className="header-title__link">
        {siteTitle} <span className="header-title__subtitle">Dashboard</span>
      </Link>
    </h1>
    {children}
  </header>
)

export default Header
