import React from 'react'

import { AuthConsumer } from './authContext'
import Button from '../button'

const Logout = () => (
  <AuthConsumer>
    {({ logout }) => (
      <Button theme={['primary', 'no-icon', 'centered']} onClick={logout}>
        <span>Logout</span>
      </Button>
    )}
  </AuthConsumer>
)

export default Logout
