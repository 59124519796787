import React from 'react';

import CalendarAppt from './appointment';
import CalendarDay from './day';
import CalendarToday from './today';
import Log from './log';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

const getMaxAppointmentCount = appointments => {
  if (appointments.length === 0) {
    return 0;
  }
  const groupByDay = groupBy(appointments, 'day');
  const sortByLength = sortBy(groupByDay, 'length').reverse();


  return sortByLength[0].length;
};


const CalendarWeek = ({ client = null, appointments, days, defaultAvailability, refresh, setWeekday, setCalendarDayScope }) => {
  const clickDay = (index) => {
    setWeekday(index);
    setCalendarDayScope(true);
  };


  return (
    <div className="calendar">
      <div className="calendar--label-spacer"></div>
      <div className="calendar-week-header">
        {days.map((day,index) => <div key={day.key} onClick={() => clickDay(index)}>
          <CalendarDay day={day}/>
        </div>)}
      </div>
      {Object.keys(appointments).map((key, i) => {
        // Create a dummy array to populate zebra stripes;
        const stripes = Array.apply(
          null,
          Array(getMaxAppointmentCount(appointments[key]) + 1)
        );

        return (
          <React.Fragment key={i}>
            <div className="calendar--label">{key}</div>
            <div className="calendar-week">
              {days.map(day => (
                <Log
                  key={`${day.key}-${key}`}
                  day={day}
                  slot={key}
                  count={
                    appointments[key].filter(
                      appointment => appointment.day === day.day
                    ).length
                  }
                  defaultAvailability={defaultAvailability}
                  refresh={refresh}
                />
              ))}
              {stripes.map((item, i) => (
                <div
                  key={i}
                  className={`calendar__stripe calendar__stripe--${
                    i % 2 ? 'even' : 'odd'
                  }`}
                  style={{
                    gridRow: `${i + 2} / ${i + 3}`,
                  }}
                />
              ))}
              {days.map((item, i) => (
                <div
                  key={i}
                  className={`calendar__column`}
                  style={{
                    gridColumn: `${i + 1} / ${i + 2}`,
                  }}
                />
              ))}
              {appointments[key]
                .sort((a, b) => {
                  if (a.appointment === '3' || b.appointment === '3') {
                    return a.appointment === '3' ? -1 : 1;
                  }
                  return (
                    parseInt(a.appointment, 10) - parseInt(b.appointment, 10)
                  )
                })
                .map(appointment => (
                  <CalendarAppt
                    key={appointment.id}
                    id={appointment.id}
                    data={appointment}
                    showTime={true}
                    refresh={refresh}
                  />
                ))}
            </div>
          </React.Fragment>
        )
      })}
      <CalendarToday days={days}/>
    </div>
  );
};

export default CalendarWeek;
