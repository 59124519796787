/**
 *  API: log
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';
import get from 'lodash/get';

const ROUTE = '/api/logs';

export const logSave = data => request(ROUTE, {
  method: 'post',
  data
});

// Create a Context
const LogAddAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromLoader = loader => {
  if (loader && loader.response) {
    return get(loader, 'response.data');
  }
  if (loader && loader.error) {
    return get(loader, 'error.response.data.errors[0].message');
  }

  return {};
};

export const LogAddAPIProvider = ({ children }) => {
  const loader = useLoads('logs-add', logSave, {
    defer: true
  });

  // Raw response data
  const data = getDataFromLoader(loader);

  return (
    <LogAddAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </LogAddAPIContext.Provider>
  );
};

LogAddAPIProvider.propTypes = {
  children: PropTypes.element
};

export default LogAddAPIContext;
