import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { withPrefix } from 'gatsby';

import Icon from '../icon';
import Logout from '../auth/logout';
import UserAPIContext from '../../services/user';

/**
 * WithPrefix is only for a temporary static image
 * this should be an absolute url once this endpoint exists
 */
const Account = () => {
  const [
    expanded,
    setExpanded
  ] = useState(false);

  const userAPI = useContext(UserAPIContext);

  const toggleExpanded = () => setExpanded(prevExpanded => !prevExpanded);

  // Component did mount
  useEffect(() => {
    userAPI.load();
  }, []);

  const {
    data,
    error = {},
    isPending,
    isResolved,
    isRejected
  } = userAPI;

  const { name = '', image = withPrefix('/img/profile.png'), location = { name: '' } } = data;

  return (
    <div className="account">
      {isPending && <div>loading...</div> }
      {isResolved && (
        <div className="account__content" onClick={toggleExpanded}>
          <div className="account__image">
            {image && name && <img src={decodeURIComponent(image)} alt={name} />}
          </div>
          <div className="account__summary">
            <div className="account__name">{name}</div>
            {typeof location === 'object' && <div className="account__location">{location.name}</div>}
          </div>
          <Icon
            icon={expanded
              ? 'arrowCircleUp'
              : 'arrowCircleDown'}
          />
        </div>
      )}

      {isRejected && <div type="danger">{error && error.message}</div> }

      {isResolved && (
        <CSSTransition
          in={expanded}
          timeout={200}
          classNames="account__dropdown-">
          <div className="account__dropdown" aria-expanded={expanded}>
            <Logout />
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

export default Account;
