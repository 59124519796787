/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect } from 'react';

import { Formik, Form, Field } from 'formik';

import Button from '../button';
import FieldText from '../field/formikText';
import FieldTextArea from '../field/textarea';
import LogAddAPIContext, { LogAddAPIProvider } from '../../services/logAdd';
import LogUpdateAPIContext, { LogUpdateAPIProvider } from '../../services/logUpdate';

const LogFormInner = ({ date, availability, note, slot, logId, onSuccess = () => {} }) => {
  const LogAddAPI = useContext(LogAddAPIContext);
  const LogUpdateAPI = useContext(LogUpdateAPIContext);

  useEffect(() => {
    if (LogAddAPI.isResolved) {
      onSuccess();
    }
  }, [LogAddAPI.isResolved]);

  useEffect(() => {
    if (LogUpdateAPI.isResolved) {
      onSuccess();
    }
  }, [LogUpdateAPI.isResolved]);

  const save = ({ availability, note }) => {
    const data = {
      date
    };

    if (!logId) {
      data.am = {
        availability: 4,
        note: ""
      };

      data.pm = {
        availability: 4,
        note: ""
      };
    }

    data[slot] = {
      availability,
      note
    };

    if (logId) {
      LogUpdateAPI.load(data, logId);
      console.log(data);
    } else {
      LogAddAPI.load(data);
      console.log(data);
    }
  };

  if (LogAddAPI.isResolved || LogUpdateAPI.isResolved) {
    return (<p>Memo Saved!</p>);
  }

  if (LogAddAPI.isRejected) {
    return (<p>{LogAddAPI.data}</p>);
  }

  if (LogUpdateAPI.isRejected) {
    return (<p>{LogUpdateAPI.data}</p>);
  }

  return (
    <Formik
      initialValues={{
        availability,
        note
      }}
      enableReinitialize={true}
      validate={values => { }}
      onSubmit={(values, { setSubmitting }) => {
        save(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <Field
            type="number"
            name="availability"
          >
            {({ field }) => (
              <FieldText {...field} type="number" label="Availability" />
            )}
          </Field>
          <Field
            type="textarea"
            name="note"
          >
            {({ field }) => (
              <FieldTextArea {...field} id="note" label="Note" placeholder="Add notes" />
            )}
          </Field>
          <Button theme={[
            'primary',
            'no-icon'
          ]} disabled={isSubmitting} type="submit">Save</Button>
        </Form>
      )}
    </Formik>
  );
};

const LogForm = props => (
  <LogUpdateAPIProvider>
    <LogAddAPIProvider>
      <LogFormInner {...props} />
    </LogAddAPIProvider>
  </LogUpdateAPIProvider>
);

export default LogForm;
