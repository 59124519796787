import React from 'react'
import PropTypes from 'prop-types';

const FieldNumber = ({
  id,
  label,
  value,
  step,
  placeholder,
  disabled,
  autoFocus,
  min,
  max,
  onChange,
  onBlur,
  onFocus,
  className,
  required
}) => (
  <div className={`field-text field-number ${className}`}>
    <label className="field-text--label" htmlFor={id}>{label}</label>
    <div className="field-text--input">
      <input
        type="number"
        id={id}
        name={id}
        disabled={disabled}
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={value === null ? '' : value}
        step={step}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        min={min}
        max={max}
        required={required}
      />
    </div>
  </div>
);

FieldNumber.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.number,
  placeholder: PropTypes.number,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

FieldNumber.defaultProps = {
  id: '',
  label: '',
  value: '',
  step: 1,
  placeholder: null,
  disabled: false,
  autoFocus: false,
  onChange: () => {},
  onBlur: () => {},
  required: false,
}

export default FieldNumber
