import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorsContext, { ErrorsConsumer } from '../context/errors';
import { Link } from "@reach/router";
import DialogContext from '../context/dialog';
import CancelApptForm from './appointment/cancelApptForm';
import ErrorsOnPageMessage from './ErrorsOnPageMessage';

const CustomLink = props => {

  const dialog = useContext(DialogContext);
  const errors = useContext(ErrorsContext);

  const onClick = event => {
    if (errors.errorStatus === true) {
      event.preventDefault();
      dialog.openWith({
        heading: 'Errors on Page',
        content: <ErrorsOnPageMessage closeDialog={dialog.close} moveOnTo={props.to} errors={errors} />
      });
    }
  };

  return (
    <>
      <Link
        {...props}
        onClick={onClick}
      />
    </>
  );
};

CustomLink.propTypes = {
  partial: PropTypes.number
};


export default CustomLink;
