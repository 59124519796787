import React from 'react'
import format from 'date-fns/format'

const CalendarCaption = ({ date }) => (
  <div className="DayPicker-Caption" role="heading">
    <div>{format(date, 'MMMM')}</div>
  </div>
)

export default CalendarCaption
