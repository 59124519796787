/* eslint-disable max-statements */
import React, { useContext, useEffect, useState } from 'react';
import { navigate, Location } from '@reach/router';

import isWithinRange from 'date-fns/is_within_range';
import isSameDay from 'date-fns/is_same_day';
import { getHours, startOfToday, toLocationDate, toUtcDate, eachDay } from './../../utils/dateTime';

import moment from 'moment-timezone';

import Card from './../card';
import MenuWeek from './../menuWeek';
import Overlay from './../overlay';

import DateYear from './../date/year';
import CalendarWeek from './week';
import CalendarWeekSimple from './weekSimple';
import CalendarDaySingle from './daySingle';

import AppointmentsAPIContext from '../../services/appointments';
import LogsAPIContext, {LogsAPIProvider} from '../../services/logs';
import LocationContext from './../../context/location';
import TasAPIContext, { TasAPIProvider } from '../../services/tas';
import MenuDay from '../menuDay';
import LocationsAPIContext from '../../services/locations';

const qs = require('qs');

/**
 * Custom isPast to just check if date is yesterday.
 * @param {date} date
 * @return {bool}
 */
const isPast = (date, tz) => date.isBefore(startOfToday(tz));

const isToday = (date, tz) => date.isSame(startOfToday(tz), 'day');

const dayKey = date => date.format('ddd').toLowerCase();

const getWeekQuery = (start, end, tz) => ({
  start: moment(start).tz(tz)
    .format('YYYY-MM-DD'),
  end: moment(end).tz(tz)
    .format('YYYY-MM-DD')
});

const processAppointments = (appointments, tz) => {
  const days = {};

  return appointments.map(item => {
    const start = moment.utc(item.date.start).tz(tz);
    item.day = dayKey(start);

    if (days.hasOwnProperty(item.day)) {
      days[item.day] += 1;
    } else {
      days[item.day] = 0;
    }

    item.pos = days[item.day]
    item.past = isPast(start, tz)
    item.today = isToday(start, tz)

    return item
  })
}

const processDays = (dates, logs, tz) => {
  return dates.map(date => {
    const tzDate = moment.tz(date, tz);
    return {
      date: tzDate,
      day: dayKey(tzDate),
      key: tzDate.format('YYYY-MM-DD'),
      past: isPast(tzDate, tz),
      today: isToday(tzDate, tz),
      log: logs.filter(log => isSameDay(date, log.date), tz)
    }
  })
}

const splitAppointments = (appointments, tz) => {
  return {
    am: processAppointments(appointments.filter(appointment => getHours(appointment.date.start, tz) < 12), tz),
    pm: processAppointments(appointments.filter(appointment => getHours(appointment.date.start, tz) >= 12), tz)
  }
}

const normalizeAppointments = (appointments = [], start, end, tz) => {
  const range = appointments.filter(appointment => isWithinRange(appointment.date.start, start, end));

  // eslint-disable-next-line id-length
  const sorted = range.sort((a, b) => a.date.start - b.date.start);

  return splitAppointments(sorted, tz);
};

const CalendarInner = ({ add, client, start, end, path, number }) => {

  const [
    calendarDayScope,
    setCalendarDayScope
  ] = useState(false);

  const [
    weekday,
    setWeekday
  ] = useState(0);

  const appointmentsAPI = useContext(AppointmentsAPIContext);
  const logsApi = useContext(LogsAPIContext);
  const tasAPI = useContext(TasAPIContext);
  const loc = useContext(LocationContext);
  const locationsAPI = useContext(LocationsAPIContext);
  const tz = loc.timezone();
  const emptyAppointments = {
    am: [],
    pm: [],
  }

  const [
    open,
    setOpen
  ] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const [
    appointments,
    setAppointments
  ] = useState(emptyAppointments);

  const [
    days,
    setDays
  ] = useState([]);

  const localStart = moment.tz(start, tz).startOf('day');
  const localEnd = moment.tz(end, tz).endOf('day');

  // eslint-disable-next-line max-statements
  const fetch = (dateStart, dateEnd, redirect = true) => {

    const dates = {
      dateStart: moment.tz(dateStart, tz).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'),
      dateEnd: moment.tz(dateEnd, tz).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
    };
    appointmentsAPI.load(dates);
    logsApi.load(dates);

    if (open) {
      setOpen(false);
    }

    if (redirect) {
      const query = {
        start: dateStart,
        end: dateEnd
      };

      navigate(`${path}?${qs.stringify(query)}`, { state: { client } });
    }
  };

  const clearAppointments = () => setAppointments(emptyAppointments)

  const refresh = () => {
    fetch(start, end, false);
  }

  const calendarSwitchScope = calendarDayScope => (
    <>
      { calendarDayScope
        ? <CalendarDaySingle
          client={client}
          appointments={appointments}
          days={days.slice(weekday, weekday + 1)}
          defaultAvailability={logsApi.data.defaults}
          number={number}
          refresh={refresh}
          weekday={weekday}
        />
        : <CalendarWeek
          client={client}
          appointments={appointments}
          days={days.slice(0, 6)}
          defaultAvailability={logsApi.data.defaults}
          number={number}
          setWeekday={setWeekday}
          setCalendarDayScope={setCalendarDayScope}
          refresh={refresh}
        />
      }
    </>
  );

  useEffect(() => {
    tasAPI.load();
  }, [])

  useEffect(() => {
    if (!locationsAPI.isResolved || loc.id === null) {
      return;
    }
    clearAppointments()
    refresh()
  }, [start, end, tz, locationsAPI.isResolved])

  useEffect(() => {
    setAppointments(normalizeAppointments(appointmentsAPI.data.appointments, localStart, localEnd, tz));
  }, [appointmentsAPI.data]);

  useEffect(() => {
    if (logsApi.isResolved) {
      setDays(processDays(eachDay(localStart, localEnd, tz), logsApi.data.logs, tz));
    }
  }, [logsApi.data]);

  const fetchPrev = () => {
    setWeekday(5);
    fetch(localStart.clone().subtract(1, 'week')
      .format('YYYY-MM-DD'), localEnd.clone().subtract(1, 'week')
      .format('YYYY-MM-DD'));
  };

  const fetchNext = () => {
    setWeekday(0);
    fetch(localStart.clone().add(1, 'week')
      .format('YYYY-MM-DD'), localEnd.clone().add(1, 'week')
      .format('YYYY-MM-DD'));
  };

  const prevDay = () => {
    if (weekday > 0) {
      setWeekday(weekday - 1);
    } else {
      fetchPrev();
    }
  };

  const nextDay = () => {
    if (weekday < 5) {
      setWeekday(weekday + 1);
    } else {
      fetchNext();
    }
  };

  return (
    <>
      <Overlay show={open}>
        {add
          ? ''
          : <div className={'toggle calendar-toggle'}>
            <div className={`toggle__option${calendarDayScope ? '' : ' is-active'}`} onClick={() => setCalendarDayScope(false)} >Week</div>
            <div className={`toggle__option${calendarDayScope ? ' is-active' : ''}`} onClick={() => setCalendarDayScope(true)} >Day</div>
          </div>
        }
        {calendarDayScope
          ? <MenuDay prev={prevDay} next={nextDay} start={start} weekday={weekday} open={open} toggle={toggle} />
          : <MenuWeek prev={fetchPrev} next={fetchNext} start={start} end={end} open={open} toggle={toggle} />
        }
        <div className="overlay-pane">
          <DateYear setRange={fetch} setWeekday={setWeekday} start={start} end={end} open={open} />
        </div>
      </Overlay>
      <Card modifier="card--offset-label">
        {add
          ? <CalendarWeekSimple
            client={client}
            appointments={appointments}
            days={days.slice(0, 6)}
            defaultAvailability={logsApi.data.defaults}
            add={add}
            number={number}
            refresh={refresh}
          />
          : calendarSwitchScope(calendarDayScope)
        }
      </Card>
    </>
  );
};

const Calendar = props => (
    <LogsAPIProvider>
  <TasAPIProvider>
    <CalendarInner {...props} />
  </TasAPIProvider>
    </LogsAPIProvider>
);

export default Calendar;
