/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect } from 'react'

import { Formik, Form, Field } from 'formik'
import moment from 'moment-timezone'

import Button from '../button'
import Icon from '../icon'
import FieldText from '../field/formikText'
import { defaultDuration } from '../../lib/defaults';
import AppointmentAddAPIContext, { AppointmentAddAPIProvider } from '../../services/appointmentAdd';
import AppointmentUpdateAPIContext, { AppointmentUpdateAPIProvider } from '../../services/appointmentUpdate';

import { timeToDate } from '../../utils/dateTime';

const mockLocation = {
  defaultTimes: {
    am: '08:30',
    pm: '13:00'
  }
}

const apptTime = (start, duration) => {
  const time = start.split(':').map(num => parseInt(num, 10))

  // This is so stupid do real time math instead.
  const startNum = time[0] + (time[1] / 60)
  const endNum = startNum + duration

  const endTime = endNum.toString().split('.').map(num => parseInt(num))

  if (endTime[1]) {
    endTime[1] = (endTime[1] > 10 ? (endTime[1] / 100) : (endTime[1] / 10)) * 60
  }
  else {
    endTime[1] = '00'
  }

  return endTime.join(':')
}

const formatDateTime = (date, time, tz) => {
  const newDate = timeToDate(date, time, tz);
  return newDate.format();
}

const formatDate = (date, tz) => date.format('dddd, MMM DD');

const formatTime = (date, start, end, tz) => {
  if (!start) {
    return null;
  }

  if (!end) {
    return (<span className="calendar-heading--day">{timeToDate(date, start, tz).format('h:mmA')}</span>);
  }

  return (<span className="calendar-heading--day">{timeToDate(date, start, tz).format('h:mmA')}&mdash;{timeToDate(date, end, tz).format('h:mmA')}</span>);
}

const AddApptFormInner = ({ date, slot, number, client, id, location, ta, onSuccess = () => {} }) => {
  const AppointmentAPI = useContext(id ? AppointmentUpdateAPIContext : AppointmentAddAPIContext );
  const tz = location.timezone();

  useEffect(() => {
    if (AppointmentAPI.isResolved) {
      onSuccess();
    }
  }, [AppointmentAPI.isResolved])

  const save = {
    add: ({start, end}) => {
      const data = {
        appointment: number,
        client,
        location: location.id,
        date: {
          start: formatDateTime(date, start, tz),
          end: formatDateTime(date, end, tz)
        },
        ta: {
          id: ta.id
        }
      }

      AppointmentAPI.load(data);
    },
    update: ({ start, end }) => {
      const data = {
        date: {
          start: formatDateTime(date, start, tz),
          end: formatDateTime(date, end, tz)
        },
      }

      AppointmentAPI.load(id, data);
    }
  }

  if (AppointmentAPI.isResolved) {
    return (<p>Appointment Saved!</p>);
  }

  return (
    <Formik
      initialValues={{
        start: mockLocation.defaultTimes[slot],
        end: apptTime(mockLocation.defaultTimes[slot], defaultDuration(number))
      }}
      enableReinitialize={true}
      validate={values => { }}
      onSubmit={(values, { setSubmitting }) => {
        save[id ? 'update' : 'add'](values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <Field
            type="time"
            name="start"
          >
            {({ field }) => (
              <FieldText {...field} type="time" label="Start Time" step={60 * 15}/>
            )}
          </Field>
          <Field
            type="time"
            name="end"
          >
            {({ field }) => (
              <FieldText {...field} type="time" label="End Time" step={60 * 15} />
            )}
          </Field>
          <div className="calendar-appointment-date">
            <span className="calendar-heading--day">{formatDate(date, tz)}</span>&nbsp;
            {formatTime(date, values.start, values.end, tz)}
          </div>
          <Button theme="primary" disabled={AppointmentAPI.isPending || AppointmentAPI.isReloading} type="submit">
            <span className="button__label" disabled={AppointmentAPI.isPending || AppointmentAPI.isReloading}>Save</span>
            <Icon icon="forward" theme="primary" />
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const AddApptForm = props => {
  const Provider = props.id
    ? AppointmentUpdateAPIProvider
    : AppointmentAddAPIProvider;

  return (
    <Provider>
      <AddApptFormInner {...props} />
    </Provider>
  );
}

export default AddApptForm;
