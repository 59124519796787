/**
 *  API: log
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';
import get from 'lodash/get';

const route = id => `/api/logs/${id}`;

export const logSave = (data, id) => request(route(id), {
  method: 'post',
  data
});

// Create a Context
const LogUpdateAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromLoader = loader => {
  if (loader && loader.response) {
    return get(loader, 'response.data');
  }
  if (loader && loader.error) {
    return get(loader, 'error.response.data.errors[0].message');
  }

  return {};
};

export const LogUpdateAPIProvider = ({ children }) => {
  const loader = useLoads('logs-update', logSave, {
    defer: true
  });

  // Raw response data
  const data = getDataFromLoader(loader);

  return (
    <LogUpdateAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </LogUpdateAPIContext.Provider>
  );
};

LogUpdateAPIProvider.propTypes = {
  children: PropTypes.element
};

export default LogUpdateAPIContext;
