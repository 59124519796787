import React, { Component } from 'react'

const TooltipBox = ({ children, open, modifier = '' }) => (
  <div className={`tooltip tooltip__${open ? 'is-open' : 'is-closed'} tooltip__${modifier}`}>
    <div className="tooltip-inner">
      <div className="tooltip-content">
        {children}
      </div>
    </div>
  </div>
);

class Tooltip extends Component {

  constructor() {
    super()
    this.state = {
      open: false
    }
  }

  toggle = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { info, children } = this.props
    const { open } = this.state


    return (
      <div className="status-item" onMouseEnter={this.toggle} onMouseLeave={this.toggle}>
        <TooltipBox open={open} modifier="top">
          {info}
        </TooltipBox>
        {children}
      </div>
    )

  }
}

export default Tooltip
