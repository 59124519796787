/**
 *  API: reportPreview
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'
import { get } from '../utils/objects';

const route = id => `/api/clients/${id}/report-preview`;

export const reportPreviewLoad = id => () => request(route(id));

// Create a Context
const ReportPreviewAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  return get(response, 'data', {})
}

const getReportPreviewDOM = response => {
  const htmlString = get(response, 'data');

  if (!htmlString) {
    return;
  }

  return new DOMParser().parseFromString(htmlString, 'text/html');;
}

const getReportPreviewHTML = response => {
  const doc = getReportPreviewDOM(response);

  if (!doc) {
    return '';
  }

  return doc.querySelector('.report-root').outerHTML;
}

const injectReportStyles = response => {
  const doc = getReportPreviewDOM(response);

  if (!doc) {
    return '';
  }

  const css = doc.querySelector('#report-css');
  document.head.appendChild(css);
}

const getReportPreviewStyles = response => {
  const htmlString = get(response, 'data');

  if (!htmlString) {
    return '';
  }

  return htmlString;

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  return document.querySelector('.report-root').innerHTML;
}

// const getPattern = (response, patternId) => {
//   const reportPreview = getReportPreview(response);

//   return reportPreview.find((pattern => pattern.id === patternId));
// }

export const ReportPreviewAPIProvider = ({ children, id }) => {
  const loader = useLoads('report-preview', reportPreviewLoad, {
    variables: [id],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ReportPreviewAPIContext.Provider value={ Object.assign({}, loader, {
      id,
      data,
      getReportPreviewHTML: () => getReportPreviewHTML(loader.response),
      injectReportStyles: () => injectReportStyles(loader.response)
    })}>
      {children}
    </ReportPreviewAPIContext.Provider>
  )
}

ReportPreviewAPIProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element
}

export default ReportPreviewAPIContext
