import React, { useContext } from 'react'

import Button from '../button'
import Icon from '../icon'
import DialogContext from '../../context/dialog';
import AddApptForm from './addApptForm';
import LocationContext from '../../context/location';
import UserAPIContext from '../../services/user';

const AddApptButton = ({ day, date, slot, number, client, refresh, id }) => {
  const dialog = useContext(DialogContext);
  const location = useContext(LocationContext);
  const UserAPI = useContext(UserAPIContext);

  const text = id ? 'Reschedule' : 'Add Appointment';
  const modifier = id ? 'swap' : 'plus';
  const heading = id ? 'Update Appointment' : 'Add Appointment';

  const onClick = () => {
    dialog.openWith({
      heading,
      content: <AddApptForm
        date={date}
        slot={slot}
        number={number}
        client={client}
        onSuccess={() => {
          refresh();
          setTimeout(() => {
            dialog.close();
          }, 1000);
        }}
        id={id}
        location={location}
        // Assume the TA is the current user. We may need to make this selectable.
        ta={UserAPI.data}
      />,
    });
  }

  return (
    <div className={`calendar__cell calendar-appt-add calendar-appt-${day}`}>
      <Button theme="secondary" onClick={onClick}>
        <Icon icon="calendar" theme="secondary" modifier={modifier} />
        <span
          className="button__label"
          data-slot={slot}
          value={date}
        >
          {text}
        </span>
      </Button>
    </div>
  )
}

export default AddApptButton
