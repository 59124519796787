import moment from "moment-timezone";
// import drupalSettings from "drupalSettings";
// import get from "lodash/get";

const DEFAULT_TIMEZONE = 'America/New_York';

/**
 * Converts a date and time into a moment instance in the correct timezone
 */
export const timeToDate = (date, time, tz) => {
  const newDate = date.clone();
  const segments = time.split(':');

  newDate.hours(segments[0]);
  newDate.minutes(segments[1]);

  return newDate;
}

export const getHours = (date, tz = DEFAULT_TIMEZONE) => moment.utc(date).tz(tz).hours();

export const startOfToday = (tz = DEFAULT_TIMEZONE) => moment().tz(tz).startOf('day');

export const toLocationDate = (date, tz = DEFAULT_TIMEZONE) => moment.tz(moment(date).format('YYYY-MM-DD'), tz).toDate();

export const toUtcDate = (date) => moment.utc(moment(date).format('YYYY-MM-DD')).toDate();

export const eachDay = (start, end, tz) => {
  const days = [];
  const locEnd = moment.tz(end, tz).startOf('day');

  for (var m = moment.tz(start, tz); m.diff(locEnd, 'days') <= 0; m.add(1, 'days')) {
    days.push(m.format('YYYY-MM-DD'));
  }
  return days;
}

/**
 * Converts a date from the specified timezone, into
 * that same date in the local timezone. This is useful
 * for displaying different timezones in local time.
 * For example:
 *  Converting 12:30pm New York time to 12:30 Los Angeles time.
 * @param {Date} dateTime
 *  Source Date object.
 * @param {String} tz
 *  Source timezone.
 * @returns {Date}
 *  A new Date where the hours.
 */
export const convertDateTimeToDate = (datetime, tz) => {
  const mom = moment.tz(datetime, tz);
  return new Date(mom.year(), mom.month(), mom.date(), mom.hour(), mom.minute(), 0);
};

/**
 * Convert a local Date into the Moment specified in the BigCalendar timezone
 * @param {Date} date
 */
export const convertDateToDateTime = (date, tz) => {
  const dateM = moment.tz(date, tz);
  return moment.tz({
    year: dateM.year(),
    month: dateM.month(),
    date: dateM.date(),
    hour: dateM.hour(),
    minute: dateM.minute(),
  }, tz);
};
