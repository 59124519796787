import React from 'react'

const Overlay = ({ show, children }) => (
  <div className="overlay">
    <div className={`overlay-panel overlay-panel__${show ? 'show' : 'hide'}`}>
    </div>
    { children }
  </div>
);

export default Overlay
