import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ReportPreviewAPIProvider } from '../services/reportPreview';
import Auth from './auth'
import favicon from '../images/favicon.ico';

const ReportPreviewLayout = ({ mode = '', children }) => {

  return (
    <StaticQuery
      query={graphql`
        query ReportTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Report' },
              { name: 'keywords', content: '' }
            ]}
          >
            <html lang="en" />
            <link rel="icon" href={favicon} />
            <body className="report-root" />
          </Helmet>
          <Auth>
            <div id="report-container" className="report-container">
              {children}
            </div>
          </Auth>
        </>
      )}
    />
  )
}

ReportPreviewLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default ReportPreviewLayout
