/**
 *  API: tas
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';

const ROUTE = '/api/tas';

export const tasLoad = () => request(ROUTE);

// Create a Context
const TasAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {
    tas: [],
    links: []
  };
}

/**
 * Creates an array of objects with key value pairs for use with form elements.
 * @param {Object} response
 *   The response returned from an API request
 * @returns {Array}
 *   An array of objects with key and label properties
 */
const getOptionsFromResponse = response => {
  const data = getDataFromResponse(response);

  // Ensure data is an array.
  const values = Array.isArray(data.tas)
    ? data.tas
    : Object.values(data.tas);

  return values.map(item => ({
    id: item.id,
    label: item.name
  }));
}

export const TasAPIProvider = ({ children }) => {
  const loader = useLoads('tas', tasLoad, {
    context: 'tas',
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  // Location key / value pa
  const options = getOptionsFromResponse(loader.response);

  return (
    <TasAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      options
    })}>
      {children}
    </TasAPIContext.Provider>
  )
}

TasAPIProvider.propTypes = {
  children: PropTypes.element
}

export default TasAPIContext
