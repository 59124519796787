import React from 'react'

const label = amount => {
  amount = parseFloat(amount)

  return `currency ${amount < 0 ? 'currency--negative' : ''}`
}

const format = amount => {
  amount = parseFloat(amount, 10);

  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}
const Currency = ({amount, modifier}) => (
  <span className={`${label(amount)} ${modifier && `currency--${modifier}`}`} data-amount={amount}>{format(amount)}</span>
);

export default Currency
