import React from 'react';

const ofToday = (day) => day.today;

const CalendarToday = ({ days }) => {
  const indexOfToday = days.findIndex(ofToday);

  return indexOfToday > -1
    ? <div className={'calendar__today'} style={{ gridColumn: `${indexOfToday + 2} / ${indexOfToday + 3}`}}></div>
    : null;
};

export default CalendarToday
