import React, {useContext} from 'react';

import LocationContext from '../context/location';
import getWeekRange from '../utils/getWeekRange'

import Calendar from '../components/calendar'
import ClientLayout from '../components/client/ClientLayout'
import DashboardLayout from '../components/dashboard/DashboardLayout';
import Page from '../components/page';

const IndexPageInner = ({ location }) => {
  const loc = useContext(LocationContext);

  const range = getWeekRange(location, loc.timezone());
  const path = location.pathname;

  return (
    <Calendar path={path} start={range.start} end={range.end} />
  )
}

const IndexPage = ({location}) => (
  <Page authenticated={true}>
    <DashboardLayout>
      <ClientLayout>
        <IndexPageInner location={location} />
      </ClientLayout>
    </DashboardLayout>
  </Page>
);

export default IndexPage
