import React from 'react'
import PropTypes from 'prop-types';

import { ClientAPIProvider } from '../../services/client';
import { ClientAddAPIProvider } from '../../services/clientAdd';
import { AppointmentsAPIProvider } from '../../services/appointments';
import {LogsAPIProvider} from '../../services/logs';

const ClientLayout = props => {

  // If we don't have an ID, use the client add provider.
  const ClientProvider = props.id
    ? ClientAPIProvider
    : ClientAddAPIProvider;

  return (
    <ClientProvider id={props.id}>
      <LogsAPIProvider>
        <AppointmentsAPIProvider>
          {props.children}
        </AppointmentsAPIProvider>
      </LogsAPIProvider>
    </ClientProvider>
  )
}

ClientLayout.propTypes = {
  children: PropTypes.element,
  id: PropTypes.string
}

export default ClientLayout
