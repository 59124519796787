/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect } from 'react'

import { Formik, Form } from 'formik'

import Button from '../button'
import Icon from '../icon'
import AppointmentDeleteAPIContext, { AppointmentDeleteAPIProvider } from '../../services/appointmentDelete';

const CancelApptFormInner = ({ id, onSuccess = () => { }, onCancel = () => { } }) => {
  const AppointmentAPI = useContext(AppointmentDeleteAPIContext);
  const { isPending } = AppointmentAPI;

  useEffect(() => {
    if (AppointmentAPI.isResolved) {
      onSuccess();
    }
  }, [AppointmentAPI.isResolved])

  const onSubmit = () => {
    AppointmentAPI.load(id);
  };

  if (AppointmentAPI.isResolved) {
    return (<p>Appointment canceled!</p>);
  }

  return (
    <>
      <p>This operation cannot be undone!</p>
      <div className="button-group">
        <Button theme="primary" disabled={isPending} onClick={onSubmit}>
          <span className="button__label" disabled={isPending}>Yes</span>
        </Button>
        <Button theme="secondary" disabled={isPending} onClick={onCancel}>
          <span className="button__label" disabled={isPending}>No</span>
        </Button>
      </div>
    </>
  )
}

const CancelApptForm = props => {

  return (
    <AppointmentDeleteAPIProvider>
      <CancelApptFormInner {...props} />
    </AppointmentDeleteAPIProvider>
  );
}

export default CancelApptForm;
